nav {
  width: 100%;
  background: var(--color-primary);
  padding: 16px 12%;
}

.nav-container,
.nav-menu {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 12%;
}

.nav-container {
  position: relative;
  justify-content: space-between;
  font-family: 'Julius Sans One', sans-serif;
}

.nav-menu {
  flex-grow: 1;
  justify-content: start;
  max-width: 1512px;
}

.nav-links {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-grow: 1;
  padding: 0;
}

.nav-links li {
  list-style: none;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 15px;
}

.nav-links li a {
  text-decoration: none;
  color: var(--color-text-dark);
}

.nav-links li a.active {
  border-bottom: 1px solid var(--color-text-dark);
}

.nav-logo {
  width: 72px;
  height: 72px;
}

nav .social-icons {
  display: flex;
  gap: 18px;
  flex-shrink: 0;
  align-items: center;
}

nav .social-icons img {
  width: 24px;
  aspect-ratio: 1;
}

.nav-icon {
  background-color: transparent;
  border: none;
  cursor: pointer;
  position: absolute;
  display: none;
  padding: 0;
}

.open-icon {
  top: 50%;
  right: 0;
  transform: translateY(-50%);
}

.close-icon {
  top: 32px;
  right: 7%;
  transform: translateX(-50%);
}

.nav-menu .mobile-only {
  display: none;
}

.nav-menu .mobile-only hr {
  border: 0.5px solid hsla(27, 19%, 38%, 1);
  margin-top: 40px;
  margin-bottom: 16px;
  width: 100%;
}

.nav-menu .mobile-only h2 {
  font-weight: bolder;
}

.nav-menu .mobile-only-location p {
  max-width: 180px;
}

.nav-menu .mobile-only-footnote {
  font-size: 12px;
}

@media (max-width: 699px) {
  nav {
    padding-inline: 8%;
  }

  .open-icon {
    display: block;
  }

  .nav-menu {
    position: fixed;
    padding: 50vh 10%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: fixed;
    background-color: var(--color-primary);
    height: 100vh;
    width: 100vw;
    z-index: 9999;
    right: 0;
    top: 0;
    transform: translateX(100%);
    transition: 350ms;
  }

  .nav-menu.expanded {
    left: 0;
    transform: translateX(0%);
  }

  .nav-menu.expanded .close-icon {
    display: block;
  }

  .nav-links {
    flex-direction: column;
    justify-content: space-between;
    gap: 16px;
  }

  .nav-links li {
    font-size: 24px;
  }

  .nav-menu .mobile-only {
    display: flex;
    flex-direction: column;
    text-align: center;
    align-items: center;
    gap: 24px;
    margin-bottom: 32px;
  }

  .nav-menu .mobile-only-footnote p {
    font-size: 14px;
  }

  nav .social-icons img {
    width: 40px;
  }
}
