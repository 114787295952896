.location {
  margin-inline: auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-block: 120px;
  text-align: center;
}

#locationMap {
  width: 516px;
  aspect-ratio: initial;
}

.location__hours h2 {
  margin-bottom: 28.25px;
}

.location__hours p:nth-child(2n) {
  font-weight: 500;
}

.location__hours p:nth-child(5) {
  margin-bottom: 8.25px;
}

.location__hours p:nth-child(6) {
  margin-bottom: 37.5px;
  font-size: 15px;
  font-weight: 400;
}

.location__address {
  display: flex;
  align-items: center;
  justify-content: center;
}

.location__address img {
  width: 19.5px;
  height: 19.5px;
  margin-right: 6px;
}

#address {
  font-weight: 300;
}

@media (max-width: 999px) {
  .location {
    flex-direction: column;
    gap: 40px;
    padding-block: 64px;
  }

  .location__hours {
    text-align: center;
  }
}

@media (max-width: 599px) {
  .location__address {
    display: none;
  }

  .location__hours p:nth-child(6) {
    margin-bottom: 0;
  }
}
