.gallery {
  padding-top: 24px;
  padding-bottom: 64px;
  max-width: 75%;
  margin-inline: auto;
}

.gallery h1 {
  text-align: center;
  color: var(--color-accent);
  font-weight: 700;
  padding-block: 20px;
}

.gallery__grid {
  display: flex;
  flex-direction: column;
  gap: 32px;
}

.gallery__grid > * {
  position: relative;
  object-fit: cover;
  display: block;
  width: 100%;
  height: 100%;
}

.item-1 {
  aspect-ratio: 5/4;
  object-position: 50% 10%;
}

.item-2 {
  order: 1;
}

.item-3 {
  aspect-ratio: 10/7;
  object-position: right;
}

.item-6,
.item-7 {
  order: 2;
}

.gallery .socials-container {
  padding-inline: 0;
}

.gallery .socials-block {
  padding-block: 64px;
}

.gallery .mobile-only {
  display: none;
}

@media (min-width: 700px) and (max-width: 1099px) {
  .gallery .socials-block {
    padding-inline: 28px;
  }

  .gallery .socials-block p {
    font-size: 16px;
    line-height: 1.4em;
  }

  .gallery .socials-block img {
    width: 54px;
  }
}

@media (max-width: 799px) {
  .gallery {
    max-width: 90%;
  }
}

@media (min-width: 700px) {
  .gallery__grid {
    display: grid;
    grid-template-columns: repeat(2, minmax(200px, 1fr));
    grid-auto-rows: 148px;
    grid-auto-flow: dense;
    gap: 16px;
  }

  .item-1,
  .item-4 {
    grid-row: span 3;
  }

  .item-1 {
    object-position: 0% 8%;
  }

  .item-2 {
    grid-row: span 4;
    grid-column-start: 1;
  }

  .item-3,
  .item-5,
  .item-6,
  .item-7 {
    grid-row: span 2;
  }

  .item-1,
  .item-2,
  .item-7 {
    grid-column-start: 1;
  }

  .item-3,
  .item-4,
  .item-5,
  .item-6 {
    grid-column-start: 2;
  }

  .gallery .socials-block {
    padding-block: 0;
  }
}

@media (max-width: 699px) {
  .gallery {
    max-width: 80%;
  }
}

@media (max-width: 599px) {
  .gallery {
    padding-top: 16px;
    padding-bottom: 32px;
    max-width: 90%;
  }

  .gallery__grid {
    gap: 16px;
  }

  .gallery__socials {
    display: none;
  }

  .gallery .socials-container {
    display: none;
  }

  .gallery .mobile-only {
    background-color: var(--color-primary);
    display: flex;
    padding: 32px;
    align-items: center;
    justify-content: center;
    gap: 24px;
  }
}
