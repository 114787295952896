.slideshow {
  margin-inline: auto;
  overflow: hidden;
  padding-block: 28px;
  position: relative;
}

.slideshowSlider {
  white-space: nowrap;
  transition: ease 1000ms;
}

.slide {
  display: inline-block;
  border-radius: 40px;
}

/* Buttons */
.slideshowDotsContainer {
  background: #ffffff;
  opacity: 0.7;
  border-radius: 33.275px;
  padding: 5px;
  position: absolute;
  left: 50%;
  bottom: 56px;
  transform: translateX(-50%);
  width: 46.58px;
  height: 14.64px;
  display: flex;
  justify-content: center;
}

.slideshowDots {
  display: flex;
  justify-content: space-around;
  width: 100%;
}

.slideshowDot {
  display: inline-block;
  height: 6.13px;
  width: 6.13px;
  border-radius: 50%;

  cursor: pointer;

  background-color: #d9d9d9;
}

.slideshowDot.active {
  background-color: #7a7a7a;
}
