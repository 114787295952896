/* CSS Reset */
*,
*::before,
*::after {
  box-sizing: border-box;
}
* {
  margin: 0;
}
html,
body {
  height: 100%;
}
body {
  line-height: 1.5;
  -webkit-font-smoothing: antialiased;
}
img,
picture,
video,
canvas,
svg {
  display: block;
  max-width: 100%;
}
input,
button,
textarea,
select {
  font: inherit;
}
p,
h1,
h2,
h3,
h4,
h5,
h6 {
  overflow-wrap: break-word;
}
#root,
#__next {
  isolation: isolate;
}

/* Base styles */
:root {
  --color-primary: #f5e2e2;
  --color-secondary: #ffffff;
  --color-accent: #5f4a39;
  --color-text-light: #5a534b;
  --color-text-dark: #000000;
}

h1,
h2,
.photo_text {
  font-family: 'Julius Sans One';
  font-style: normal;
  font-weight: 400;
}

h1 {
  font-size: 37.5px;
  font-weight: bold;
  line-height: 52px;
  color: var(--color-text-dark);
}

h2 {
  font-size: 32px;
  font-weight: bolder;
}

h2,
.photo_text {
  color: var(--color-accent);
}

p {
  font-size: 18.75px;
  font-weight: 300;
  font-family: 'Nunito';
}

/* For screen readers */
.visually-hidden {
  position: absolute !important;
  height: 1px;
  width: 1px;
  overflow: hidden;
  clip: rect(1px, 1px, 1px, 1px);
  white-space: nowrap;
}

@media (max-width: 699px) {
  body:has(.nav-menu.expanded) {
    overflow: hidden;
  }
}

@media (max-width: 599px) {
  h1 {
    font-size: 32px;
    line-height: 40px;
  }

  h2 {
    font-size: 28px;
  }

  p {
    font-size: 16px;
  }
}
