.menu {
  font-family: 'Julius Sans One', sans-serif;
  padding-block: 56px;
}

.menu__headers ul {
  display: flex;
  justify-content: center;
  font-size: 28.5px;
  padding: 0;
}

.menu__headers li {
  list-style: none;
}

.menu__headers a {
  text-decoration: none;
  font-weight: bold;
  color: var(--color-accent);
}

.menu__headers span {
  margin-inline: 29.25px;
}

.menu__image {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 47px;
}

.menu__image img {
  border: 1px solid;
  border-radius: 4px;
}

.menu__download {
  display: flex;
  flex-direction: row;
  margin: 36.5px auto 9px;
  justify-content: flex-end;
  gap: 14px;
}

.menu__download img {
  width: 20px;
}

.menu__download,
.menu__image img {
  width: 655.5px;
  height: auto;
  max-width: 80%;
}

.menu__download a:first-child {
  font-size: 16.5px;
  font-weight: bold;
  text-decoration: none;
  color: var(--color-text-dark);
}

.pink-bg {
  background-color: #fce5f7;
}

@media (max-width: 699px) {
  .menu__headers ul {
    font-size: 24px;
  }

  .menu__headers span {
    margin-inline: 18px;
  }

  .menu__image {
    gap: 32px;
  }
}

@media (max-width: 499px) {
  .menu {
    padding-block: 32px;
  }

  .menu__headers ul {
    font-size: 20px;
  }

  .menu__headers span {
    margin-inline: 10px;
  }

  .menu__download,
  .menu__image img {
    max-width: 90%;
  }
}
