.home__welcome-banner,
.home__about-border,
.home__photos,
.home__gallery img,
.location {
  width: 67%;
}

.home__landing {
  background-image: url('../../public/img/restaurantFront.jpg');
  opacity: 0.75;
  height: 580px;
  background-position: 58%;
  background-size: cover;
  background-repeat: no-repeat;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  justify-content: space-between;
  padding-block: 200px 56px;
  transition: 350ms ease-in-out;
}

.home__landing button {
  cursor: pointer;
  display: block;
  background-color: transparent;
  border: none;
  z-index: 999;
  padding-inline: 0;
}

.home__welcome-banner::before {
  content: '';
  position: absolute;
  width: 100%;
  height: 99.39px;
  bottom: 0;
  left: 0;

  background: linear-gradient(
    180deg,
    #000000 0%,
    rgba(0, 0, 0, 0.979167) 0.01%,
    rgba(0, 0, 0, 0.954687) 0.02%,
    rgba(0, 0, 0, 0.950083) 0.03%,
    #424242 0.04%,
    rgba(81, 81, 81, 0.32) 43.43%,
    rgba(143, 143, 143, 0.12) 61.7%,
    rgba(159, 158, 158, 0) 78.36%,
    rgba(0, 0, 0, 0) 100%,
    rgba(0, 0, 0, 0) 100%
  );
  transform: rotate(180deg);
}

.home__welcome-banner {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgb(255, 255, 255, 0.8);
  box-shadow: 0px 3px 3px rgba(0, 0, 0, 0.25);

  padding-block: 34px;
  border-radius: 7.5px;
}

.home__welcome-text {
  text-align: center;
}

.home__welcome-text p {
  font-size: 26.25px;
  line-height: 36px;
}

.home__about-box {
  padding-block: 128px;
  margin: auto;
  display: flex;
  justify-content: center;
  align-items: center;
}

.home__about-border {
  border: 0.75px solid var(--color-accent);
  border-radius: 4px;
}

.home__about-row {
  display: flex;
  align-items: center;
  gap: 102px;
  padding: 40px 80px;
}

.home__about-column p {
  line-height: 25.58px;
}

.home__image-banner img {
  height: 560px;
  width: 100%;
  object-fit: cover;
  object-position: top;
}

.home__menu {
  background-color: var(--color-primary);
  width: 100%;
}

.home__menu-row {
  max-width: 950px;
  width: 80%;
  margin-inline: auto;
  display: grid;
  grid-template-columns: 4fr 5fr;
  /* grid-template-rows: 1fr 2.5fr 1fr; */
  padding-block: 120px;
  gap: 120px;
  row-gap: 16px;
}

.home__menu img {
  grid-row: span 3;
  height: 100%;
  object-fit: cover;
  border-radius: 4px;
}

.home__menu p {
  font-size: 20px;
}

.home__menu button {
  font-size: 18.75px;
  border-radius: 5.25px;
  height: 50px;
  width: 135px;
}

.home__photos {
  padding-block: 48px;
  margin-inline: auto;
  display: flex;
  gap: 10px;
}

.home__photos > * {
  width: 33.3%;
  position: relative;
}

.home__photos img,
.overlay {
  border-radius: 4px;
}

.home__photos img {
  aspect-ratio: 1;
  object-fit: cover;
  object-position: center;
  border-radius: 4px;
  transition: transform 350ms;
}

.home__photos a {
  overflow: hidden;
}

.home__photos a:hover img {
  transform: scale(1.25);
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  z-index: 1000;
  background-color: rgb(0, 0, 0, 0.3);
  transition: opacity linear 0.2s;
}

.home__photos .photo_text img {
  display: none;
}

.home__photos > *:hover .overlay {
  opacity: 1;
}

.home__photos .photo_text {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 28.5px;
  font-weight: bold;
  color: white;
  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.home__gallery {
  background-color: var(--color-primary);
  text-align: center;
  padding-block: 48px;
}

.home__gallery img {
  aspect-ratio: 2.35/1;
  margin: auto;
  object-fit: cover;
  object-position: center;
  border-radius: 4px;
}

.home__gallery button {
  font-size: 18.75px;
  padding: 8px 16px;
  border-radius: 5.25px;
}

#moveDown {
  -webkit-animation: movingDown 1s;
  animation: movingDown 1000ms;
}
#moveUp {
  -webkit-animation: movingUp 1s;
  animation: movingUp 1s;
}

@keyframes movingDown {
  from {
    transform: translateY(-50px);
    opacity: 0.2;
  }
  to {
    transform: translateY(0px);
    opacity: 1;
  }
}
@keyframes movingUp {
  from {
    transform: translateY(30px);
    opacity: 0.2;
  }
  to {
    transform: translateY(0px);
    opacity: 1;
  }
}


@media (max-width: 1199px) {
  .home__welcome-banner,
  .home__about-border,
  .home__photos,
  .home__gallery img,
  .location {
    width: 80%;
  }

  .home__menu-row p {
    font-size: 18px;
  }

  .home__image-banner img {
    height: 480px;
  }
}

@media (max-width: 999px) {
  .home__menu-row {
    display: flex;
    flex-direction: column;
    gap: 32px;
    width: 50%;
    padding-block: 64px;
  }

  .home__menu-row h2,
  .home__menu-row a {
    text-align: center;
  }

  .home__menu-row h2 {
    order: -1;
  }

  .home__menu-row img {
    aspect-ratio: 3 / 2;
    object-position: 50% 30%;
  }

  .home__menu-viewMenu {
    display: flex;
    justify-content: center;
  }
}

@media (max-width: 900px) {
  .home__about-box,
  .home__photos,
  .location {
    padding-block: 48px;
  }

  .home__about-row {
    flex-direction: column;
    gap: 24px;
  }
}

@media (max-width: 799px) {
  .home__photos .photo_text {
    font-size: 26px;
  }
}

@media (max-width: 699px) {
  .home__landing h1,
  .home__landing p {
    max-width: 90%;
    margin-inline: auto;
  }

  .home__menu-row,
  .home__gallery img {
    width: 80%;
  }

  .home__menu-viewMenu {
    display: flex;
    justify-content: center;
  }

  .home__about-row {
    padding-inline: 56px;
    gap: 16px;
  }

  .home__photos {
    flex-direction: column;
    gap: 32px;
    padding-block: 80px;
    width: 90%;
  }

  .home__photos > * {
    width: 100%;
  }

  .home__photos img {
    aspect-ratio: 2.275 / 1;
  }

  .overlay {
    opacity: 1;
    background-color: rgb(0, 0, 0, 0.4);
  }

  .home__photos .photo_text {
    top: auto;
    right: auto;
    left: 4%;
    bottom: 2%;
    transform: none;
    font-size: 32px;
    font-weight: lighter;
    display: flex;
    align-items: center;
    gap: 8px;
  }

  .home__photos .photo_text img {
    display: inline;
    height: 100%;
    aspect-ratio: initial;
  }

  .home__photos .photo_text::before {
    content: 'View ';
    position: absolute;
    top: -75%;
    left: 0;
  }

  .home__photos a:nth-child(2) > img {
    object-position: 50% 51.5%;
  }

  .home__gallery img {
    aspect-ratio: 1.6 / 1;
  }
}

@media (max-width: 599px) {
  .home__welcome-banner,
  .home__about-border,
  .home__photos,
  .home__gallery img,
  .location {
    width: 90%;
  }

  .home__welcome-text p {
    font-size: 24px;
  }

  .home__photos {
    padding-block: 48px;
  }

  .home__menu p {
    font-size: 16px;
  }
}

@media (min-width: 500px) {
  .home__landing button img {
    height: 32px;
  }

  .home__landing:has(button:hover) {
    padding-bottom: 24px;
  }

  .home__landing button:hover {
    padding-top: 32px;
  }
}

@media (max-width: 499px) {
  .home__landing {
    padding-block: 64px 16px;
    height: 464px;
  }

  .home__about-row {
    padding: 32px 24px;
    gap: 8px;
  }

  .home__welcome-banner {
    padding-block: 18px;
  }

  .home__welcome-text p {
    font-size: 18px;
    line-height: 24px;
  }

  .home__image-banner img {
    height: 320px;
    object-position: left;
  }

  .home__menu-row {
    padding-block: 32px;
  }
}
